import cloneDeep from 'lodash/cloneDeep';
import { localizedString } from '@languages';

import { noEmojies } from '@lib/constants/regex';
import { isEmptyExpiryDateAllowed } from '@lib/utils/checkExpiredDocument';
import { DL_NUMBER_VALIDATION } from '@spotMobileConfig';

export const defaultLettersAndSymbolsRegexMatch = {
  message: localizedString('lettersAndSymbolsOnly'),
  regex: noEmojies
};

const defaultLettersAndNumbersRegexMatch = {
  message: localizedString('lettersAndNumbersOnly'),
  regex: /^([a-zA-Z0-9]+)$/
};

const defaultLettersAndNumbersAndParenthesisRegexMatch = {
  message: localizedString('lettersAndNumbersAndParenthesisOnly'),
  regex: /^([a-zA-Z0-9()]+)$/
};

const maxLength50CharsValidation = {
  message: localizedString('max50Chars'),
  length: 50
};

const firstName = {
  isRequired: localizedString('pleaseEnterFirstName'),
  isRegexMatch: defaultLettersAndSymbolsRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const middleName = {
  isRegexMatch: defaultLettersAndSymbolsRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const lastName = {
  isRequired: localizedString('verifyDetails.VERIFY_DETAILS_MESSAGE_LAST_NAME'),
  isRegexMatch: defaultLettersAndSymbolsRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const dateOfBirth = {
  isRequired: localizedString('pleaseEnterDateOfBirth'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const expiryDate = {
  isRequired: localizedString('pleaseEnterDateOfExpiry'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const visaStartDate = {
  isRequired: localizedString('pleaseEnterVisaStartDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const visaEndDate = {
  isRequired: localizedString('pleaseEnterVisaEndDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const licenceNumber = {
  isRequired: localizedString('pleaseEnterLicenceNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMinLength: {
    message: localizedString('4CharactersMinimum'),
    length: 4
  },
  isExactLength: {
    message: localizedString('licenceNumberMustBe8Chars'),
    length: 8
  }
};

if (!DL_NUMBER_VALIDATION) {
  delete licenceNumber.isExactLength;
}

const licenceVersion = {
  isRequired: localizedString('pleaseEnterVersionNumber'),
  isRegexMatch: {
    message: localizedString('numbersOnly'),
    regex: /^([0-9]+)$/
  },
  isExactLength: {
    message: localizedString('versionNumberMustBe3Numbers'),
    length: 3
  }
};

const passportNumber = {
  isRequired: localizedString('pleaseEnterPassportNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: {
    message: localizedString('alphaNumeric9DigitsMaximum'),
    length: 9
  }
};

const cardNumber = {
  isRequired: localizedString('pleaseEnterCardNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: {
    message: localizedString('max10Chars'),
    length: 10
  }
};

const cardNumberVIC = {
  isRequired: localizedString('pleaseEnterCardNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isExactLength: {
    message: localizedString('cardNumberMustBe8Chars'),
    length: 8
  }
};

const idNumber = {
  isRequired: localizedString('pleaseEnterIDNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const idNumberHK = {
  isRequired: localizedString('pleaseEnterIDNumber'),
  isRegexMatch: defaultLettersAndNumbersAndParenthesisRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const taxNumber = {
  isRequired: localizedString('pleaseEnterTaxNumber'),
  isRegexMatch: {
    message: localizedString('numbersDashAndDotsOnly'),
    regex: /^([0-9.-]+)$/
  },
  isMaxLength: maxLength50CharsValidation
};

const documentNumber = {
  isRequired: localizedString('pleaseEnterDocumentNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const dlclassCodeBFrom = {
  isRequired: localizedString('pleaseEnterBClassIssueDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/.]+)$/
  }
};

const unitNumber = {
  isRegexMatch: defaultLettersAndSymbolsRegexMatch, // TODO: maybe allow everthing???
  isMaxLength: maxLength50CharsValidation
};

export const getFormValidation = ({
  idType = '',
  countryOfIssue = '',
  values = {},
  flowType = '',
  cardType = ''
} = {}) => {
  const { state } = values;
  const cardNumberValidation = isVicDL({ idType, countryOfIssue, state })
    ? cardNumberVIC
    : cardNumber;

  const { isRequired, ...expiryDateWithoutRequired } = expiryDate;
  const expiryDateValidation = isEmptyExpiryDateAllowed({ flowType, cardType })
    ? expiryDateWithoutRequired
    : expiryDate;

  return cloneDeep({
    firstName,
    lastName,
    middleName,
    dateOfBirth,
    expiryDate: expiryDateValidation,
    visaStartDate,
    visaEndDate,
    licenceNumber,
    licenceVersion,
    passportNumber,
    cardNumber: cardNumberValidation,
    idNumber
  });
};

export const getFieldsValidation = ({
  idType = '',
  countryOfIssue = '',
  values = {},
  flowType = '',
  cardType = ''
}) => {
  const { state } = values;
  const cardNumberValidation = isVicDL({ idType, countryOfIssue, state })
    ? cardNumberVIC
    : cardNumber;
  const idNumberValidation = countryOfIssue.toUpperCase() === 'HONG KONG' ? idNumberHK : idNumber;

  const { isRequired, ...expiryDateWithoutRequired } = expiryDate;

  return cloneDeep({
    firstName: { firstName },
    lastName: { lastName },
    middleName: { middleName },
    dateOfBirth: { dateOfBirth },
    expiryDate: {
      expiryDate: isEmptyExpiryDateAllowed({ flowType, cardType })
        ? expiryDateWithoutRequired
        : expiryDate
    },
    visaStartDate: { visaStartDate },
    visaEndDate: { visaEndDate },
    licenceNumber: { licenceNumber },
    licenceVersion: { licenceVersion },
    passportNumber: { passportNumber },
    cardNumber: { cardNumber: cardNumberValidation },
    idNumber: { idNumber: idNumberValidation },
    taxNumber: { taxNumber },
    documentNumber: { documentNumber },
    dlclassCodeBFrom: { dlclassCodeBFrom },
    unitNumber: { unitNumber }
  });
};

function isVicDL({ idType, countryOfIssue, state }) {
  return (
    idType.match(/LICENCE/i) && countryOfIssue.toUpperCase() === 'AUSTRALIA' && state === 'VIC'
  );
}
